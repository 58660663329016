<template>
  <SideWrapper>
    <SideAvatar :editRoute="{ name: $routeName.ASSISTANCE_PROVIDE_EDIT_PROFILE }"></SideAvatar>
    <SideOrdersAndIncome :ordersCount="10" :income="5000"></SideOrdersAndIncome>
    <SideCollapse>
      <SidePersoneAttrs></SidePersoneAttrs>
      <SideServicesList></SideServicesList>
    </SideCollapse>
    <template
      v-if="!user.my_company"
    >
      <div class="side-panel__switch">
        <div class="side-panel__label">Оказываю помощь</div>
        <v-switch
          @change="switchIsWorking"
          :input-value="isWorking"
        />
      </div>
      <div
        class="side-panel__switch"
      >
        <div class="side-panel__label">Предложения от организаций</div>
        <CustomSwitch
          @change="switchIsPersonalInfo"
          :input-value="isPersonalInfo"
        ></CustomSwitch>
      </div>
      <div>
        <router-link
          :to="{ name: 'ASSISTANCE_PROVIDE_LIST_INVITATIONS'}"
          class="btn btn--invite btn--border"
        >
          Приглашения от компаний
        </router-link>
      </div>
    </template>
  </SideWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SideAvatar from '@/component/Module/SidePanel/SideAvatar.vue';
import SidePersoneAttrs from '@/component/Module/SidePanel/SidePersoneAttrs.vue';
import SideCollapse from '@/component/Module/SidePanel/SideCollapse.vue';
import SideOrdersAndIncome from '@/component/Module/SidePanel/SideOrdersAndIncome.vue';
import SideServicesList from '@/component/Module/SidePanel/SideServicesList.vue';
import SideWrapper from '@/component/Module/SidePanel/SideWrapper.vue';
import CustomSwitch from "@component/UI/Form/CustomSwitch";

export default {
  name: 'SidePanel',
  components: {
    CustomSwitch,
    SideAvatar,
    SidePersoneAttrs,
    SideCollapse,
    SideOrdersAndIncome,
    SideServicesList,
    SideWrapper,
  },
  data() {
    return {
      switchBtn: true,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isPersonalInfo() {
        return this.user.is_personal_info;
    },
    isWorking() {
      return this.user.working;
    }
  },
  methods: {
    ...mapActions('user', ['updateUserProfileData']),
    async switchIsWorking(value) {
      await this.updateUserProfileData({ user: { working: value }});
    },
    switchIsPersonalInfo({ value, done }) {
      return this.updateUserProfileData({
        user: {
          is_personal_info: value
        }
      }).finally(() => done());
    }
  }
};
</script>

<style lang="scss" scoped>
.side-panel {
  .btn--invite {
    margin: 20px;
  }
}
.side-panel__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 14px;
  border-bottom: 1px solid rgba(#000000, 0.2);
  .side-panel__label {
    font-size: 17px;
  }
}
@include respond-to('sm') {
  .side-panel__switch {
    height: 60px;
    padding: 0 16px;
    .side-panel__label {
      font-size: 19px;
    }
  }
}



</style>
