<template>
  <div class="user-side-info">
    <template v-if="user.service.length">
      <div class="user-side-info__title">Услуги:</div>
      <div class="user-side-info__services">
        <template v-for="(service, i) in user.service">
          <div class="ic-ell" :key="i">
            <div class="ic-ell__icon">
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
            </div>
            <span class="ic-ell__text">{{ service.responsibility_name }}</span>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="user-side-info__title">У вас еще нет услуг, которые вы&nbsp;оказываете</div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
// TODO add loadind services
export default {
  props: {
    services: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
  }
};
</script>

<style lang="scss" scoped>



// user-side-info
.user-side-info {
  .user-side-info__title {
    margin-bottom: 16px;
    color: rgba(#020202, 0.9);
    font-size: 17px;
    font-weight: 600;
  }

  .user-side-info__services {
    .ic-ell {
      margin-bottom: 10px;
    }
  }
  @include respond-to('sm') {
    .user-side-info__title {
      margin-bottom: 20px;
      font-size: 19px;
    }

  }
}


</style>
