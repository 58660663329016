<template>
  <v-switch
    @change="onChange"
    v-bind="$attrs"
    :loading="loading"
  ></v-switch>
</template>

<script>
export default {
  name: "CustomSwitch",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    onChange(value) {
      this.loading = true;
      new Promise(resolve => {
        this.$emit('change', { value, done: resolve });
      }).finally(() => this.loading = false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-progress-circular--indeterminate {
    .v-progress-circular__overlay {
      stroke: var(--color-prime);
    }
  }
}
</style>
